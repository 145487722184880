import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom"; // Import useParams
import ImageTitle from "../assets/image/des_title_main.png";
import TextEditor from "../components/TextEditor";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const BlogDetail = () => {
  const [content, setContent] = useState("");
  const [post, setPost] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams(); // Lấy slug từ URL
  const location = useLocation();
  const routeLevel2 = `/${location.pathname.split("/")[1]}`

  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Giới thiệu";
    window.scrollTo(0, 0)
    getDetailNew();
  }, [slug]); // Thêm slug vào dependency array

  const getDetailNew = () => {
    setIsLoading(true);
    axios
      .get(`/api/v1/posts/slug/` + slug) // Sử dụng slug trong URL
      .then(async (res) => {
        setIsLoading(false);

        if (res.data) {
          setContent(res.data.content);
          setPost(res.data);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        // toast("Có lỗi xảy ra, vui lòng thử lại sau", {
        //   type: "error",
        //   autoClose: 2000,
        // });
        navigate("*")
      });
  };

  return (
    <Container className="container-wrapper my-5">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={routeLevel2} className="breadcrumb-link">Tin tức & sự kiện</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`#`} className="breadcrumb-link-select">{post.title}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="title-layout mb-3"></div>
      <div className="title-main">
        <span>{post.title}</span>
        <i>
          <img src={ImageTitle} alt="Title" />
        </i>
      </div>
      <div>
        {isLoading ? (
          <p className="text-center">Đang tải...</p>
        ) : content ? (
          <TextEditor
            disable
            hideToolbar={true}
            setContents={content}
            onChange={() => { }}
            style={{ border: "none" }}
          />
        ) : (
          <p className="text-center">Chưa có dữ liệu</p>
        )}
      </div>
    </Container>
  );
};

export default BlogDetail;
