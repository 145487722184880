import React from "react";
import { Link } from "react-router-dom";

const NewItem = ({ data }) => {
    const { slug, imagePath, title, description } = data || [];

    return (
        <div className="news-item-container">
            {/* Phần hình ảnh bên trái */}
            <div className="news-item-image">
                <Link to={slug} title={title} className="text-decoration-none">
                    <img
                        className="img-fluid"
                        src={imagePath}
                        alt={title}
                        title={title}
                    />
                </Link>
            </div>

            {/* Phần nội dung bên phải */}
            <div className="news-item-content">
                <h3 className="mb-3">
                    <Link to={slug} className="text-decoration-none text-primary fw-bold">
                        {title}
                    </Link>
                </h3>
                <p className="text-muted"><Link to={slug}>{description}</Link></p>
            </div>
        </div>
    );
};

export default NewItem;
