import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SolutionAddModal = ({ id, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageData, setImageData] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    maps: "",
    image: ""
  });

  useEffect(() => {
    if (id) {
      axios.get('/api/v1/places/' + id)
        .then(res => {
          setFormData(res.data)
        })
        .catch(err => console.log(err))
    }
    axios.get("/api/v1/images")
      .then(res => {
        setImageData(res.data.images)
      })
      .catch(err => console.log(err))
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/places", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm địa chỉ thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setErrors({});
    axios.put("/api/v1/places/" + id, formData)
      .then(res => {
        setIsSendForm(false);
        toast("Cập nhật địa chỉ thành công", {
          type: "success",
          autoClose: 1000,
        });
        handleCloseModal(true);
      })
      .catch(err => {
        console.log(err);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
      })
  };

  const validateForm = async (data) => {
    const errors = {};

    if (!data.name || data.name.trim() === "") {
      errors.name = "Tên không được để trống.";
    }
    if (!data.address || data.address.trim() === "") {
      errors.address = "Địa chỉ không được để trống.";
    }
    if (!data.maps || data.maps.trim() === "") {
      errors.maps = "Bản đồ không được để trống.";
    }

    return errors;
  };

  const removeImagePath = (str) => {
    return str.replace('/images/', '');
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm mới địa chỉ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.name}
              onChange={(e) => {
                setErrors({ ...errors, name: "" })
                setFormData({ ...formData, name: e.target.value })
              }}
              isInvalid={!!errors.name} />
            <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.address}
              onChange={(e) => {
                setErrors({ ...errors, address: "" })
                setFormData({ ...formData, address: e.target.value })
              }}
              isInvalid={!!errors.address} />
            <Form.Label>Địa chỉ<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.maps}
              onChange={(e) => {
                setErrors({ ...errors, maps: "" })
                setFormData({ ...formData, maps: e.target.value })
              }}
              isInvalid={!!errors.maps} />
            <Form.Label>Bản đồ<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.maps}</Form.Control.Feedback>
          </Form.Floating>
          <div className="mb-3">
            {formData.image ?
              <img
                src={formData.image}
                alt="Image"
                style={{ width: '250px', height: 'auto' }}
              />
              : ""
            }
          </div>
          <Form.Floating className="mb-3">
            <Form.Control
              as="select"
              value={formData.image}
              onChange={(e) => {
                setErrors({ ...errors, image: "" });
                setFormData({ ...formData, image: e.target.value });
              }}
              isInvalid={!!errors.image}
            >
              <option value="">--Chọn hình ảnh--</option>
              {imageData.map((image) => (
                <option key={image.id} value={image.path}>
                  {removeImagePath(image.path)}
                </option>
              ))}
            </Form.Control>
            <Form.Label>Hình ảnh<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.imagePath}</Form.Control.Feedback>
          </Form.Floating>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
