import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProductInfoModal from "./ProductInfoModal"
import { Pagination } from "react-bootstrap";

const ProductInfoTable = () => {
  const { id } = useParams()
  const [productInfo, setProductInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)
  const [keyword, setKeyword] = useState("")
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getProductInfo();
  }, [keyword, currentPage]);

  const getProductInfo = () => {
    axios
      .get("/api/v1/product-infos/product-id/" + id, {
        params: {
          page: currentPage,
          limit: 5,
          keyword: keyword
        }
      })
      .then((res) => {
        setProductInfo(res.data.productInfos || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa thông tin này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/product-infos/${id}`)
          .then((res) => {
            toast("Xoá thông tin thành công", {
              type: "success",
              autoClose: 1000,
            });
            getProductInfo()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getProductInfo();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm chi tiết chương trình
      </Button>
      <input
        type="text"
        placeholder="Tìm kiếm chi tiết chương trình"
        className="form-control mb-2 float-start mt-3"
        style={{ width: 350 }}
        onChange={(e) => {
          setCurrentPage(1)
          setKeyword(e.target.value)
        }}
      />
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Tên</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {productInfo.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{item.name}</span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => handleShowAddModal(item.id)}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(item.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      {showModal ? (
        <ProductInfoModal
          id={updateId}
          addId={id}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default ProductInfoTable;
