import React, { useEffect, useState } from "react";
import Slider from "react-slick"; // Nếu bạn muốn sử dụng thư viện slider như react-slick
import "aos/dist/aos.css"; // Nếu bạn đang sử dụng AOS cho hiệu ứng
// import "./styles.css"; // Nếu bạn có file CSS riêng
import ImageTitle from "../assets/image/des_title_main2.png";
import imageArrow from "../assets/image/arrows.png";
import hd1 from "../assets/image/Hoạt động.jpg";
import hd2 from "../assets/image/Hoạt động_2.jpg";
import hd3 from "../assets/image/Hoạt động_3.jpg";
import hd4 from "../assets/image/Hoạt động_4.jpg";
import hd5 from "../assets/image/Hoạt động_5.jpg";
import hd6 from "../assets/image/Hoạt động_6.jpg";
import hd7 from "../assets/image/Hoạt động_7.jpg";
import hd8 from "../assets/image/Hoạt động_8.jpg";
import hd9 from "../assets/image/Hoạt động_9.jpg";
import hd10 from "../assets/image/Hoạt động_10.jpg";
import Image1 from "../assets/image/hinh-ve-bang-but-chi-cute-3643.jpg";
import Image2 from "../assets/image/capture-5868.png";
import { Link } from "react-router-dom";
import axios from "axios";

const imageData = [
  {
    src: hd1,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd2,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd3,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd4,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd5,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd6,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd7,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd8,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd9,
    alt: "Mầm non An Thơ",
  },
  {
    src: hd10,
    alt: "Mầm non An Thơ",
  },
];

const LibraryComponent = () => {

  const [imageData, setImageData] = useState([])

  useEffect(() => {
    getImagesData();
  }, [])

  const getImagesData = () => {
    axios.get('/api/v1/images')
    .then(res => {
      const imageFilter = res.data.images.filter(e => e.type == 2)
      setImageData(imageFilter)
    })  
    .catch(err => console.log(err))
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    rows: 2,
    responsive: [
      { breakpoint: 1199, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 577, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 425, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <div
      className="thuvien-index"

      // data-aos="fade-up" data-aos-duration="1000"
    >
      <div className="wrap-content position-relative">
        <div className="title-main">
          <span className="color2">thư viện ảnh</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="thuvien-contain">
          <Slider
            {...sliderSettings}
            // className="owl-page owl-carousel owl-theme"
          >
            {imageData.map((col, index) => (
              <div className="thuvien-col" key={index}>
                <div className="thuvien-item">
                  <a href={col.href} className="scale-img">
                    <img
                      className="w-100 img-library"
                      loading="lazy"
                      src={col.path}
                      alt={col.path}
                    />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="btn-xemthem">
          <Link className="text-decoration-none spacing-letter" to={`/thu-vien-anh`}>
            Khám phá thêm <img src={imageArrow} alt="arrow icon" />
          </Link>
        </div>
        <div className="des-album--after">
          <img src={Image2} alt="MẦM NON AN THƠ" />
        </div>
        <div className="des-album--before">
          <img src={Image1} alt="MẦM NON AN THƠ" />
        </div>
      </div>
    </div>
  );
};

export default LibraryComponent;
