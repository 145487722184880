import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TextEditor from "../../TextEditor";

const SolutionAddModal = ({ id, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageData, setImageData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    description: "",
    imagePath: "",
    content: "",
    categoryId: "",
  });

  useEffect(() => {
    if (id) {
      axios.get("/api/v1/posts/" + id)
        .then(res => {
          setFormData({ ...res.data });
        })
        .catch((error) => {
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        });
    }

    axios.get("/api/v1/images")
      .then(res => {
        setImageData(res.data.images)
      })
      .catch(err => console.log(err))
    axios.get("/api/v1/categories")
      .then(res => {
        setCategoryData(res.data.categories)
      })
      .catch(err => console.log(err))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/posts", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm điều hướng thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/posts/" + id, formData)
        .then(res => {
          setIsSendForm(false);
          toast("Cập nhật điều hướng thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const validateForm = async (data) => {
    const errors = {};

    // Kiểm tra tên
    if (!data.title || data.title.trim() === "") {
      errors.title = "Tên danh mục không được để trống.";
    }

    // Kiểm tra slug
    if (!data.slug || data.slug.trim() === "") {
      errors.slug = "Slug danh mục không được để trống.";
    }

    // Kiểm tra description
    if (!data.description || data.description.trim() === "") {
      errors.description = "Mô tả không được để trống.";
    }

    // Kiểm tra content
    if (!data.content || data.content.trim() === "" || data.content.trim() === "<p><br></p>") {
      errors.content = "Nội dung không được để trống.";
    }

    //Kiểm tra image
    if (!data.imagePath || data.imagePath == 0) {
      errors.imagePath = "Vui lòng chọn hình ảnh."
    }

    //Kiểm tra category
    if (!data.categoryId || data.categoryData == 0) {
      errors.categoryId = "Vui lòng chọn danh mục"
    }

    return errors;
  };

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9-\s]/g, "")
      .trim()
      .replace(/\s+/g, "-");
  };

  const removeImagePath = (str) => {
    return str.replace('/images/', '');
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm mới danh mục</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.title}
              onChange={(e) => {
                setErrors({ ...errors, title: "", slug: "" })
                setFormData({ ...formData, title: e.target.value, slug: generateSlug(e.target.value) })
              }}
              isInvalid={!!errors.title} />
            <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.slug}
              onChange={(e) => {
                setErrors({ ...errors, slug: "" })
                setFormData({ ...formData, slug: e.target.value })
              }}
              isInvalid={!!errors.slug} />
            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.slug}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control as="textarea" value={formData.description}
              onChange={(e) => {
                setErrors({ ...errors, description: "" })
                setFormData({ ...formData, description: e.target.value })
              }}
              style={{ height: "150px" }}
              isInvalid={!!errors.description} />
            <Form.Label>Mô tả<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
          </Form.Floating>
          <div className="mb-3">
            <Form.Label>Nội dung<i className="text-danger">*</i></Form.Label>
            <TextEditor
              setContents={formData.content}
              onChange={(e) => {
                setErrors((prevFormData) => ({
                  ...prevFormData,
                  content: "",
                }))
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  content: e,
                }))
              }}
              placeholder={"Nội dung"}
              style={{ border: "1px solid #dadada", padding: 16 }}
            />
            <div style={{ color: "#DC3545", fontSize: 14 }}>
              {errors.content}
            </div>
          </div>
          <div className="mb-3">
            {formData.imagePath ?
              <img
                src={formData.imagePath}
                alt="Image"
                style={{ width: '250px', height: 'auto' }}
              />
              : ""
            }
          </div>
          <Form.Floating className="mb-3">
            <Form.Control
              as="select"
              value={formData.imagePath}
              onChange={(e) => {
                setErrors({ ...errors, imagePath: "" });
                setFormData({ ...formData, imagePath: e.target.value });
              }}
              isInvalid={!!errors.imagePath}
            >
              <option value="">--Chọn hình ảnh--</option>
              {imageData.map((image) => (
                <option key={image.id} value={image.path}>
                  {removeImagePath(image.path)}
                </option>
              ))}
            </Form.Control>
            <Form.Label>Hình ảnh<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.imagePath}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control
              as="select"
              value={formData.categoryId}
              onChange={(e) => {
                setErrors({ ...errors, categoryId: "" });
                setFormData({ ...formData, categoryId: e.target.value });
              }}
              isInvalid={!!errors.categoryId}
            >
              <option value="">--Chọn danh mục--</option>
              {categoryData.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <Form.Label>Danh mục<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.categoryId}</Form.Control.Feedback>
          </Form.Floating>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
