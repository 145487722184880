import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import UserModal from "./UserModal";
import { Pagination } from "react-bootstrap";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)
  const [resetPassword, setResetPassword] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getUser();
  }, [keyword, currentPage]);

  const getUser = () => {
    axios
      .get("/api/v1/users", {
        params: {
          page: currentPage,
          limit: 10,
          keyword: keyword
        }
      })
      .then((res) => {
        setUsers(res.data.users || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa người dùng này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/users/${id}`)
          .then((res) => {
            toast("Xoá người dùng thành công", {
              type: "success",
              autoClose: 1000,
            });
            getUser()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setResetPassword(false)
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getUser();
    }
  };

  const handleShowAddModal = (id, resetPassword) => {
    if (resetPassword) {
      setResetPassword(true)
    }
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function getGenderString(number) {
    if (number === 2) {
      return "Nữ";
    } else if (number === 1) {
      return "Nam";
    } else {
      return "Khác";
    }
  }

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm người dùng
      </Button>
      <input
        type="text"
        placeholder="Tìm kiếm người dùng"
        className="form-control mb-2 float-start mt-3"
        style={{ width: 350 }}
        onChange={(e) => {
          setCurrentPage(1)
          setKeyword(e.target.value)
        }}
      />
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Họ và tên</th>
            <th>Giới tính</th>
            <th>Ngày sinh</th>
            <th>Email</th>
            <th>Số điện thoại</th>
            <th>Tài khoản</th>
            <th>Tình trạng</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{user.name}</span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {getGenderString(user.gender)}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {formatDate(user.birthday)}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {user.email}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {user.phone}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {user.username}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {user.lock ? "đã khoá" : "chưa khoá"}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="primary"
                  onClick={() => { handleShowAddModal(user.id, true) }}
                  className="me-2"
                >
                  Cập nhật mật khẩu
                </Button>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(user.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(user.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      {showModal ? (
        <UserModal
          id={updateId}
          isResetPassword={resetPassword}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default UserTable;
