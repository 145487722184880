import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TextEditor from "../../TextEditor";

const SolutionAddModal = ({ id, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageData, setImageData] = useState([])
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    link: "",
    content: ""
  });

  useEffect(() => {
    if (id) {
      axios.get("/api/v1/products/" + id)
        .then(res => {
          setFormData({
            ...res.data
          });
        })
        .catch((error) => {
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        });
    }
    axios.get("/api/v1/images")
      .then(res => {
        setImageData(res.data.images)
      })
      .catch(err => console.log(err))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/products", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm chương trình học thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/products/" + id, formData)
        .then(res => {
          setIsSendForm(false);
          toast("Cập nhật chương trình học thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const validateForm = async (data) => {
    const errors = {};

    // Kiểm tra tên
    if (!data.title || data.title.trim() === "") {
      errors.title = "Tiêu đề không được để trống.";
    }

    // Kiểm tra tên
    if (!data.link || data.link.trim() === "") {
      errors.link = "Slug không được để trống.";
    }

    // Kiểm tra tên
    if (!data.content || data.content.trim() === "") {
      errors.content = "Nội dung không được để trống.";
    }

    // Kiểm tra image
    if (!data.image) {
      errors.image = "Vui lòng chọn hình ảnh.";
    }

    return errors;
  };

  const removeImagePath = (str) => {
    return str.replace('/images/', '');
  };

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9-\s]/g, "")
      .trim()
      .replace(/\s+/g, "-");
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>{id ? 'Chỉnh sửa' : 'Thêm mới'} chương trình học</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.title}
              onChange={(e) => {
                setErrors({ ...errors, title: "", link: "" })
                setFormData({ ...formData, title: e.target.value, link: generateSlug(e.target.value) })
              }}
              isInvalid={!!errors.title} />
            <Form.Label>Tiêu đề<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
          </Form.Floating>
          <div className="mb-3">
            {formData.image ?
              <img
                src={formData.image}
                alt="Image"
                style={{ width: '250px', height: 'auto' }}
              />
              : ""
            }
          </div>
          <Form.Floating className="mb-3">
            <Form.Control
              as="select"
              value={formData.image}
              onChange={(e) => {
                setErrors({ ...errors, image: "" });
                setFormData({ ...formData, image: e.target.value });
              }}
              isInvalid={!!errors.image}
            >
              <option value="">--Chọn hình ảnh--</option>
              {imageData.map((image) => (
                <option key={image.id} value={image.path}>
                  {removeImagePath(image.path)}
                </option>
              ))}
            </Form.Control>
            <Form.Label>Hình ảnh<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.image}</Form.Control.Feedback>
            <p style={{ fontSize: '14px' }}><span style={{ color: 'red' }}>Lưu ý:</span> Chọn hình ảnh xoá phông để hiển thị tốt hơn</p>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.link}
              onChange={(e) => {
                setErrors({ ...errors, link: "" })
                setFormData({ ...formData, link: e.target.value })
              }}
              isInvalid={!!errors.link}
            />
            <Form.Label>Slug</Form.Label>
            <Form.Control.Feedback type="invalid">{errors.link}</Form.Control.Feedback>
          </Form.Floating>
          <div className="mb-3">
            <Form.Label>Nội dung<i className="text-danger">*</i></Form.Label>
            <TextEditor
              setContents={formData.content}
              onChange={(e) => {
                setErrors((prevFormData) => ({
                  ...prevFormData,
                  content: "",
                }))
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  content: e,
                }))
              }}
              placeholder={"Nội dung"}
              style={{ border: "1px solid #dadada", padding: 16 }}
            />
            <div style={{ color: "#DC3545", fontSize: 14 }}>
              {errors.content}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
