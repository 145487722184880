import React, { useEffect, useState } from "react";
import "../index.css"
import ImageTitle from "../assets/image/des_title_main.png";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import StudyItem from "./StudyItem";
import { Pagination } from "react-bootstrap";

const StudyNew = () => {
  const location = useLocation();
  const slug = location.pathname;
  const [data, setData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
    getStudyNew()
  }, [currentPage]);

  const getStudyNew = () => {
    axios.get("/api/v1/products", {
      params: {
        page: currentPage,
        limit: 6,
        sort: "ASC"
      }
    })
      .then(async (res) => {
        const products = res.data.products;
        setTotalPages(res.data.totalPages);
        const formattedData = await Promise.all(
          products.map(async (product) => {
            const productInfo = await axios
              .get(`/api/v1/product-infos/product-id/${product.id}`)
              .then((res) => res.data.productInfos)
              .catch((err) => {
                console.error(`Error fetching product info for ID ${product.id}`, err);
                return [];
              });
            return {
              id: product.id,
              title: product.title || "",
              image: product.image || "",
              link: `/chuong-trinh-hoc/${product.link}`,
              items: productInfo,
            };
          })
        );
        setData(formattedData);
      })
      .catch((err) => console.error("Error fetching products", err));
  }

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0)
    }
  };

  return (
    <div className=" chuongtrinhhoc-index padding-main">
      <div className="wrap-content">
        {
          slug != "/" && <Breadcrumb>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`#`} className="breadcrumb-link-select">Chương trình học</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        <div className="title-main">
          <span>Chương trình học</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="study-items">
          {data.map((item, idx) => (
            <StudyItem data={item} key={idx} />
          ))}
        </div>
        {/* Pagination */}
        <Pagination className="justify-content-center mt-5">
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        </Pagination>
      </div >
    </div >
  );
};

export default StudyNew;
