import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import NavigationModal from "./NavbarModal";

const NavigationTable = () => {
  const [navbars, setNavbars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)

  useEffect(() => {
    getNavigation();
  }, []);

  const getNavigation = () => {
    axios
      .get("/api/v1/navbars", {
        params: {
          page: 1,
          limit: 5,
          keyword: ''
        }
      })
      .then((res) => {
        setNavbars(res.data.navbars || []);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa điều hướng này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/navbars/${id}`)
          .then((res) => {
            toast("Xoá điều hướng thành công", {
              type: "success",
              autoClose: 1000,
            });
            getNavigation()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getNavigation();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm điều hướng
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Tên</th>
            {/* <th>điều hướng cha</th> */}
            <th>Link</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {navbars.map((navbar, index) => (
            <tr key={navbar.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{navbar.name}</span>
              </td>
              {/* <td>
                <span className="text-ellipsis-2-lines ">
                  {navbar.parentId}
                </span>
              </td> */}
              <td>
                <span className="text-ellipsis-2-lines ">
                  {navbar.link}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(navbar.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(navbar.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal ? (
        <NavigationModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default NavigationTable;
