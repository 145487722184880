import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import ProductModal from "./ProductModal";
import { useNavigate } from "react-router-dom";

const ProductTable = () => {
  const navigate = useNavigate()
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = () => {
    axios
      .get("/api/v1/products", {
        params: {
          page: 1,
          // limit: 5,
          keyword: ''
        }
      })
      .then((res) => {
        setProducts(res.data.products || []);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa chương trình này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/products/${id}`)
          .then((res) => {
            toast("Xoá chương trình thành công", {
              type: "success",
              autoClose: 1000,
            });
            getProduct()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getProduct();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  const navigateProductInfo = (id) => {
    navigate("/manage-products/" + id)
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm chương trình
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Tiêu đề</th>
            <th>Hình ảnh</th>
            <th>Slug</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{product.title}</span>
              </td>
              <td>
                <img
                  src={product.image}
                  alt="Image"
                  style={{ width: '100px', height: 'auto' }}
                />
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {product.link}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="primary"
                  onClick={() => { navigateProductInfo(product.id) }}
                  className="me-2"
                >
                  Thông tin
                </Button>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(product.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(product.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal ? (
        <ProductModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default ProductTable;
