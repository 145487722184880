import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SolutionAddModal = ({ id, isResetPassword, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    birthday: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    lock: false
  });
  const [formReset, setFormReset] = useState({
    password: "",
    confirm: ""
  })
  const [confirmPassword, setConfirmPassword] = useState("")

  useEffect(() => {
    if (id) {
      axios.get("/api/v1/users/" + id)
        .then(res => {
          setFormData({
            ...res.data, birthday: formatDate(res.data.birthday), password: ""
          });
        })
        .catch((error) => {
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        });
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/users", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm người dùng thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/users/" + id, formData)
        .then(res => {
          setIsSendForm(false);
          toast("Cập nhật người dùng thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const validationErrors = validatePassword(formReset);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/users/" + id, {
        password: formReset.password
      })
        .then(res => {
          setIsSendForm(false);
          toast("Mật khẩu đã được làm mới", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  }

  const validateForm = (data) => {
    const errors = {};

    // Kiểm tra tên
    if (!data.name || data.name.trim() === "") {
      errors.name = "Tên không được để trống.";
    }

    // Kiểm tra giới tính
    if (!data.gender || ![1, 2, 3].includes(Number(data.gender))) {
      errors.gender = "Vui lòng chọn giới tính.";
    }

    // Kiểm tra ngày sinh
    if (!data.birthday) {
      errors.birthday = "Ngày sinh không hợp lệ.";
    } else if (new Date(data.birthday) > new Date()) {
      errors.birthday = "Ngày sinh không thể lớn hơn ngày hiện tại.";
    }

    // Kiểm tra email
    if (!data.email || data.email.trim() === "") {
      errors.email = "Email không được để trống.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email không hợp lệ.";
    }

    // Kiểm tra số điện thoại
    if (!data.phone || data.phone.trim() === "") {
      errors.phone = "Số điện thoại không được để trống.";
    } else if (!/^[0-9]{10,15}$/.test(data.phone)) {
      errors.phone = "Số điện thoại không hợp lệ.";
    }

    // Kiểm tra tên đăng nhập
    if (!data.username || data.username.trim() === "") {
      errors.username = "Tên đăng nhập không được để trống.";
    } else if (data.username.length < 3) {
      errors.username = "Tên đăng nhập phải có ít nhất 3 ký tự.";
    }

    if (!id) {
      // Kiểm tra mật khẩu
      if (!data.password || data.password.trim() === "") {
        errors.password = "Mật khẩu không được để trống.";
      } else if (data.password.length < 6) {
        errors.password = "Mật khẩu phải có ít nhất 6 ký tự.";
      }

      // Kiểm tra xác nhận mật khẩu
      if (data.password != confirmPassword) {
        errors.confirmPassword = "Xác nhận mật khẩu không khớp.";
      }
    }

    return errors;
  };

  const validatePassword = (data) => {
    const errors = {};

    // Kiểm tra mật khẩu
    if (!data.password || data.password.trim() === "") {
      errors.password = "Mật khẩu không được để trống.";
    } else if (data.password.length < 6) {
      errors.password = "Mật khẩu phải có ít nhất 6 ký tự.";
    }

    // Kiểm tra xác nhận mật khẩu
    if (data.password != data.confirm) {
      errors.confirm = "Xác nhận mật khẩu không khớp.";
    }

    return errors;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>{id ? isResetPassword ? 'Cập nhật mật khẩu' : 'Chỉnh sửa' : 'Thêm mới'} người dùng</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {!isResetPassword &&
            <>
              <Form.Floating className="mb-3">
                <Form.Control type="text" value={formData.name}
                  onChange={(e) => {
                    setErrors({ ...errors, name: "" })
                    setFormData({ ...formData, name: e.target.value })
                  }}
                  isInvalid={!!errors.name} />
                <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control as="select" value={formData.gender}
                  onChange={(e) => {
                    setErrors({ ...errors, gender: "" })
                    setFormData({ ...formData, gender: e.target.value })
                  }}
                  isInvalid={!!errors.gender}
                >
                  <option>--Chọn giới tính--</option>
                  <option value="1">Nam</option>
                  <option value="2">Nữ</option>
                  <option value="3">Khác</option>
                </Form.Control>
                <Form.Label>
                  Giới tính <i className="text-danger">*</i>
                </Form.Label>
                <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="date" value={formData.birthday}
                  onChange={(e) => {
                    setErrors({ ...errors, birthday: "" })
                    setFormData({ ...formData, birthday: e.target.value })
                  }}
                  isInvalid={!!errors.birthday}
                />
                <Form.Label>Ngày sinh<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.birthday}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="text" value={formData.email}
                  onChange={(e) => {
                    setErrors({ ...errors, email: "" })
                    setFormData({ ...formData, email: e.target.value })
                  }}
                  isInvalid={!!errors.email}
                />
                <Form.Label>Email<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="text" value={formData.phone}
                  onChange={(e) => {
                    setErrors({ ...errors, phone: "" })
                    setFormData({ ...formData, phone: e.target.value })
                  }}
                  isInvalid={!!errors.phone}
                />
                <Form.Label>Số điện thoại<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="text" value={formData.username} disabled={id ? true : false}
                  onChange={(e) => {
                    setErrors({ ...errors, username: "" })
                    setFormData({ ...formData, username: e.target.value })
                  }}
                  isInvalid={!!errors.username}
                />
                <Form.Label>Tên tài khoản<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Floating>
            </>
          }
          {!id &&
            <>
              <Form.Floating className="mb-3">
                <Form.Control type="Password" value={formData.password} disabled={id ? true : false}
                  onChange={(e) => {
                    setErrors({ ...errors, password: "" })
                    setFormData({ ...formData, password: e.target.value })
                  }}
                  isInvalid={!!errors.password}
                />
                <Form.Label>Mật khẩu<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="Password" value={confirmPassword} disabled={id ? true : false}
                  onChange={(e) => {
                    setErrors({ ...errors, confirmPassword: "" })
                    setConfirmPassword(e.target.value)
                  }}
                  isInvalid={!!errors.confirmPassword}
                />
                <Form.Label>Xác nhận lại mật khẩu<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
              </Form.Floating>
            </>
          }
          {
            !isResetPassword &&
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" label="Khoá tài khoản" checked={formData.lock} onChange={(e) => setFormData({ ...formData, lock: e.target.checked })} />
            </Form.Group>
          }
          {isResetPassword &&
            <>
              <Form.Floating className="mb-3">
                <Form.Control type="Password"
                  onChange={(e) => {
                    setErrors({ ...errors, password: "" })
                    setFormReset({ ...formReset, password: e.target.value })
                  }}
                  isInvalid={!!errors.password}
                />
                <Form.Label>Mật khẩu<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control type="Password"
                  onChange={(e) => {
                    setErrors({ ...errors, confirm: "" })
                    setFormReset({ ...formReset, confirm: e.target.value })
                  }}
                  isInvalid={!!errors.confirm}
                />
                <Form.Label>Xác nhận lại mật khẩu<i className="text-danger">*</i></Form.Label>
                <Form.Control.Feedback type="invalid">{errors.confirm}</Form.Control.Feedback>
              </Form.Floating>
            </>
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        {isResetPassword ?
          <Button
            form="add-solution-hook-form"
            variant="primary"
            disabled={isSendForm}
            onClick={handleResetPassword}
          >
            Cập nhật
          </Button>
          :
          <Button
            form="add-solution-hook-form"
            variant="primary"
            disabled={isSendForm}
            onClick={id ? handleUpdate : handleSubmit}
          >
            Lưu
          </Button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
