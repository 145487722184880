import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SolutionAddModal = ({ id, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    path: "",
    type: ""
  });

  useEffect(() => {
    if (id) {
      axios.get('/api/v1/images/' + id)
        .then(res => {
          setFormData(res.data)
        })
        .catch(err => console.log(err))
    }
  }, [id])

  const imageType = [
    {
      id: 1,
      name: 'Trang chủ'
    },
    {
      id: 2,
      name: 'Thư viện ảnh'
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(res => {
          setIsSendForm(false);
          toast("Thêm hình ảnh thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setErrors({});
    axios.put("/api/v1/images/" + id, formData)
      .then(res => {
        setIsSendForm(false);
        toast("Cập nhật hình ảnh thành công", {
          type: "success",
          autoClose: 1000,
        });
        handleCloseModal(true);
      })
      .catch(err => {
        console.log(err);
        toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
          type: "error",
          autoClose: 1000,
        });
      })
  };

  const validateForm = async (data) => {
    const errors = {};

    //Kiểm tra image
    if (!data.path) {
      errors.path = "Vui lòng chọn hình ảnh."
    }

    return errors;
  };

  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setFormData({ ...formData, path: event.target.files[0] });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm mới hình ảnh</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {!id &&
            <div className="mb-3">
              {formData.path ? (
                <div className="d-flex justify-content-center">
                  <div
                    style={{
                      aspectRatio: "900/600",
                      backgroundImage: `url("${URL.createObjectURL(
                        formData.path
                      )}")`,
                      backgroundSize: "cover",
                      width: "70%",
                    }}
                    className="image-default position-relative mb-3"
                  />
                </div>
              ) : (
                ""
              )}

              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(e) => {
                  setErrors({ ...errors, path: "" })
                  handleUploadImage(e)
                }}
                required
              />
              <div>
                <div className="d-inline fw-bold">Note: </div>
                Chọn ảnh với tỉ lệ 500:400 để được chất lượng tốt nhất.
              </div>
              <div style={{ color: "#DC3545", fontSize: 14 }}>
                {errors.path}
              </div>
            </div>
          }
          <Form.Floating className="mb-3">
            <Form.Control
              as="select"
              value={formData.type}
              onChange={(e) => {
                setErrors({ ...errors, type: "" });
                setFormData({ ...formData, type: e.target.value });
              }}
            >
              <option value="">--Chọn loại hình ảnh--</option>
              {imageType.map((image) => (
                <option key={image.id} value={image.id}>
                  {image.name}
                </option>
              ))}
            </Form.Control>
            <Form.Label>Danh mục</Form.Label>
          </Form.Floating>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
