import React, { useEffect } from "react";

import BlogNew from "../components/BlogNew";

const TinTuc = () => {
  useEffect(() => {
    document.title = "Tin tức";
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="mt-4">
      <BlogNew />
    </div>
  );
};

export default TinTuc;
