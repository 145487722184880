import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../index.css"
import ImageTitle from "../assets/image/des_title_main.png";
import imageArrow from "../assets/image/arrows.png";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import TextEditor from "../components/TextEditor";

const StudyDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ content: "" });
  const { slug } = useParams();
  console.log(slug);


  useEffect(() => {
    window.scrollTo(0, 0)
    getContentStudy()
  }, [])

  const getContentStudy = () => {
    setIsLoading(true)
    axios.get(`/api/v1/products/get-by-link/` + slug)
      .then(res => {
        setData(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <div className=" chuongtrinhhoc-index padding-main">
      <div className="wrap-content">
        {
          slug != "/" && <Breadcrumb>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`/chuong-trinh-hoc`} className="breadcrumb-link">Chương trình học</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`#`} className="breadcrumb-link-select">{data.title}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        <div className="title-main">
          <span>{data.title}</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="chuongtrinhhoc-contain">
          <div>
            {isLoading ? (
              <p className="text-center">Đang tải...</p>
            ) : data.content ? (
              <TextEditor
                disable
                hideToolbar={true}
                setContents={data.content}
                onChange={() => { }}
                style={{ border: "none" }}
              />
            ) : (
              <p className="text-center">Chưa có dữ liệu</p>
            )}
          </div>
        </div>
      </div >
    </div >
  );
};

export default StudyDetail;
