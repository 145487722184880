import React from "react";
import image1 from "../assets/image/Giới thiệu_3.jpg";
import { Link } from "react-router-dom";
const Wrap = () => {
  return (
    <div className="wrap-home">
      <div className="gioithieu-index">
        <div className="wrap-content">
          <div className="row justify-content-between align-items-center gioithieu-inside">
            <div className="col-lg-6 order-1 order-lg-0">
              <div className="gioithieu-info">
                <h3>TRƯỜNG MẦM NON AN THƠ</h3>
                <span className="text-split">
                  Trường Mầm Non là môi trường quan trọng đầu tiên ngoài gia
                  đình sẽ kết nối trẻ với kiến thức thế giới bên ngoài, là nền
                  tảng trong việc hình thành nhân cách và niềm đam mê học tập
                  tiếp theo và suốt đời ở trẻ. Trường Mầm Non AN THƠ được sinh
                  ra và mang theo niềm mong muốn là trở thành một ngôi nhà
                  chung, nơi các bậc cha mẹ tin tưởng, nơi các con tìm thấy tình
                  yêu thương, niềm vui và là nơi các con có thể chia sẻ ý kiến,
                  trải nghiệm và phát huy hết khả năng, tiềm năng của mình.
                </span>
                <div className="combo-btn d-flex justify-content-start">
                  <Link className="spacing-letter" to={`/gioi-thieu`}>
                    Xem thêm{" "}
                    <i>
                      <img src="assets/images/arrows.png" alt="" />
                    </i>
                  </Link>
                  <a className="spacing-letter" href="tel:0937030108">
                    <i>
                      <img src="assets/images/phone.png" alt="" />
                    </i>
                    0902 722 608
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-lg-1">
              <div className="gioithieu-pic">
                <div className="gioithieu-pic--contain">
                  <div className="gioithieu-pic--owl">
                    <div className="gioithieu-pic--slick">
                      <div className="gioithieu-pic-slick--item">
                        <p className="mb-0 gioithieu-pic--item">
                          <img
                            className="w-100"
                            src={image1}
                            alt=""
                            loading="lazy"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Repeat other sections similarly */}
    </div>
  );
};

export default Wrap;
