import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SolutionAddModal = ({ id, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    slug: "",
    parentId: "",
  });

  useEffect(() => {
    if (id) {
      axios.get("/api/v1/categories/" + id)
        .then(res => {
          setFormData({
            ...res.data
          });
        })
        .catch((error) => {
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        });
    }

    axios.get("/api/v1/categories", {
      params: {
        page: 1,
        limit: 5,
        keyword: ""
      }
    })
      .then(res => {
        setCategories(res.data.categories)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.post("/api/v1/categories", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm danh mục thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/categories/" + id, formData)
        .then(res => {
          setIsSendForm(false);
          toast("Cập nhật danh mục thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const validateForm = async (data) => {
    const errors = {};

    // Kiểm tra tên
    if (!data.name || data.name.trim() === "") {
      errors.name = "Tên danh mục không được để trống.";
    }

    // Kiểm tra slug
    if (!data.slug || data.slug.trim() === "") {
      errors.slug = "Slug danh mục không được để trống.";
    } else {
      try {
        const res = await axios.get("/api/v1/categories/checkSlugExists", {
          params: { slug: data.slug },
        });

        if (res.data.exists) {
          errors.slug = "Slug danh mục đã tồn tại.";
        }
      } catch (err) {
        console.error(err);
        errors.slug = "Đã có lỗi xảy ra khi kiểm tra slug.";
      }
    }

    return errors;
  };

  const generateSlug = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9-\s]/g, "")
      .trim()
      .replace(/\s+/g, "-");
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Thêm mới danh mục</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.name}
              onChange={(e) => {
                setErrors({ ...errors, name: "" })
                setFormData({ ...formData, name: e.target.value, slug: generateSlug(e.target.value) })
              }}
              isInvalid={!!errors.name} />
            <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.slug}
              onChange={(e) => {
                setErrors({ ...errors, slug: "" })
                setFormData({ ...formData, slug: e.target.value })
              }}
              isInvalid={!!errors.slug} />
            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.slug}</Form.Control.Feedback>
          </Form.Floating>
          {/* <Form.Floating className="mb-3">
            <Form.Control as="select" value={formData.parentId}
              onChange={(e) => { setFormData({ ...formData, parentId: e.target.value }) }}
            >
              <option>--Chọn danh mục cha--</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <Form.Label> Danh mục</Form.Label>
          </Form.Floating> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
