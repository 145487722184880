import React, { useEffect, useState } from "react";
import ImageTitle from "../assets/image/des_title_main.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import NewItem from "./NewItem";
import { Pagination } from "react-bootstrap";

const BlogNew = () => {
  const location = useLocation()
  const id = location.pathname

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const getPost = (id) => {
    axios
      .get("/api/v1/posts/get-by-cate-slug" + id, {
        params: {
          page: currentPage,
          limit: 10,
        }
      })
      .then((res) => {
        setData(res.data.posts)
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    if (id) {
      getPost(id)
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0)
    }
  };

  return (
    <div className="container-wrapper my-5">
      <div className="newsHot-index" data-aos="fade-up" data-aos-duration="1000">
        <div className="wrap-content">
          {
            id != "/" && <Breadcrumb>
              <Breadcrumb.Item className="breadcrumb-item-custom">
                <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumb-item-custom">
                <Link to={`#`} className="breadcrumb-link-select">Tin tức & sự kiện</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          <div className="title-main">
            <span>tin tức & sự kiện</span>
            <i>
              <img src={ImageTitle} alt="Title" />
            </i>
          </div>
          <div className="newsHot-contain">
            {data.map((item, idx) => (
              <div className="news-item-blog" key={idx}>
                <NewItem data={item} />
              </div>
            ))}
          </div>
          {/* Pagination */}
          <Pagination className="justify-content-center mt-3">
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default BlogNew;
