import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import ImageTitle from "../assets/image/des_title_main.png";
import TextEditor from "../components/TextEditor";
import { useNavigate, useParams, Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const DetailPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("")
  const [breadcrumbChild, setBreadcrumbChild] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    getDetailNew();
  }, [id]);

  const getDetailNew = () => {
    setIsLoading(true);
    axios.get(`/api/v1/posts/get-by-cate-slug/` + id)
      .then((res) => {
        setContent(res.data.posts[0].content)
        setTitle(res.data.posts[0].title)
        setIsLoading(false);
        getTitle()
      })
      .catch((err) => {
        console.log(err);
        navigate("*")
      });
  };

  const getTitle = () => {
    axios.get('/api/v1/categories/slug/' + id)
      .then(res => {
        setBreadcrumbChild(res.data.name)
        document.title = res.data.name
      })
      .catch(err => console.log(err))
  }

  return (
    <Container className="container-wrapper my-5">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`#`} className="breadcrumb-link-select">{breadcrumbChild}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="title-layout mb-3"></div>
      <div className="title-main">
        <span>{title}</span>
        <i>
          <img src={ImageTitle} alt="Title" />
        </i>
      </div>
      <div>
        {isLoading ? (
          //   <Loading />
          <p className="text-center">Chưa có dữ liệu</p>
        ) : content ? (
          <TextEditor
            disable
            hideToolbar={true}
            setContents={content}
            onChange={() => { }}
            style={{ border: "none" }}
          />
        ) : (
          ""
        )}
      </div>
    </Container>
  );
};

export default DetailPage;
