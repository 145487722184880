import React from "react";
import { Link } from "react-router-dom";

const StudyItem = ({ data }) => {
    const { image, link, title, items } = data || [];

    return (
        <div className="study-item">
            <h3 className="study-title">
                <Link to={link} className="text-split spacing-letter">
                    {title}
                </Link>
            </h3>
            <div className="study-content">
                <div className="study-list">
                    <ul>
                        {items.map((i, index) => (
                            <li key={index}>
                                <span style={{ fontSize: "14px" }}>
                                    {i.name.length > 50 ? i.name.slice(0, 50) + "..." : i.name}
                                </span>
                            </li>
                        ))}

                    </ul>
                </div>
                <div className="study-pic">
                    <img src={image} alt={image} />
                </div>
            </div>
        </div>

    )
}

export default StudyItem;