import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import CategoryModal from "./CategoryModal";
import { Pagination } from "react-bootstrap";

const CategoryTable = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getCategory();
  }, [currentPage]);

  const getCategory = () => {
    axios
      .get("/api/v1/categories", {
        params: {
          page: currentPage,
          limit: 10,
          keyword: ''
        }
      })
      .then((res) => {
        setCategories(res.data.categories || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa danh mục này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/categories/${id}`)
          .then((res) => {
            toast("Xoá danh mục thành công", {
              type: "success",
              autoClose: 1000,
            });
            getCategory()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getCategory();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm danh mục
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Tên</th>
            <th>Slug</th>
            {/* <th>danh mục cha</th> */}
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{category.name}</span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {category.slug}
                </span>
              </td>
              {/* <td>
                <span className="text-ellipsis-2-lines ">
                  {category.parentId}
                </span>
              </td> */}
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(category.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(category.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      {showModal ? (
        <CategoryModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default CategoryTable;
