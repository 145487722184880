import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import { Pagination } from "react-bootstrap";

const CustomerTable = () => {
  const [customers, setCustomers] = useState([]);
  const [keyword, setKeyword] = useState("")
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getCustomer();
  }, [keyword, currentPage]);

  const getCustomer = () => {
    axios
      .get("/api/v1/customers", {
        params: {
          page: currentPage,
          limit: 10,
          keyword: keyword
        }
      })
      .then((res) => {
        setCustomers(res.data.customers || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa người liên hệ này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/customers/${id}`)
          .then((res) => {
            toast("Xoá người liên hệ thành công", {
              type: "success",
              autoClose: 1000,
            });
            getCustomer()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Tìm kiếm người đăng ký"
        className="form-control mb-2 float-start mt-3"
        style={{ width: 350 }}
        onChange={(e) => {
          setCurrentPage(1)
          setKeyword(e.target.value)
        }}
      />
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Họ và tên</th>
            <th>Số điện thoại</th>
            <th>Nội dung</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr key={customer.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{customer.fullname}</span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {customer.phone}
                </span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {customer.content}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(customer.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
    </>
  );
};

export default CustomerTable;
