import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ImageTitle from "../assets/image/des_title_main.png";
import { Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Pagination } from "react-bootstrap";
import { Modal, Button } from 'react-bootstrap';

const ImageLibraryPage = () => {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const [show, setShow] = useState(false); // Điều khiển việc hiển thị modal
  const [selectedImage, setSelectedImage] = useState(null); // Hình ảnh được chọn

  // Mở modal và set hình ảnh được chọn
  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setShow(true);
  };

  // Đóng modal
  const handleClose = () => {
    setShow(false);
    setSelectedImage(null); // Reset hình ảnh khi đóng modal
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
  }, [currentPage]);

  const getData = () => {
    setIsLoading(true);
    axios.get(`/api/v1/images/get-by-type/2`, {
      params: {
        page: currentPage,
        limit: 16,
      }
    })
      .then((res) => {
        setData(res.data.images)
        setTotalPages(res.data.totalPages)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0)
    }
  };

  return (
    <Container className="container-wrapper my-5">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item-custom">
          <Link to={`#`} className="breadcrumb-link-select">Thư viện ảnh</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="title-layout mb-3"></div>
      <div className="title-main">
        <span>Thư viện ảnh</span>
        <i>
          <img src={ImageTitle} alt="Title" />
        </i>
      </div>
      <div>
        {isLoading ? (
          //   <Loading />
          <p className="text-center">Chưa có dữ liệu</p>
        ) : data ? (
          <div className="grid-view-library">
            {data.map((item, index) => (
              <div className="grid-item-library"
                key={index}
                onClick={() => handleImageClick(item.path)}
              >
                <img src={item.path} alt={`Image ${index}`} className="library-image" />
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
      {/* Pagination */}
      <Pagination className="justify-content-center mt-3">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Xem hình ảnh</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Selected"
            className="img-fluid"
            style={{ width: '100%', height: 'auto' }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ImageLibraryPage;
