import React, { useState } from "react";
import ImageTitle from "../assets/image/des_title_main.png";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link, useLocation } from "react-router-dom";

const FormComponent = () => {
  const location = useLocation();
  const slug = location.pathname;
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    content: "",
  });
  const [isSending, setIsSending] = useState(false);

  const sendMail = (e) => {
    e.preventDefault();
    setIsSending(true);
    const isValid = validateFormData(formData)
    if (isValid == 0) {
      axios.post(`/api/v1/customers`, formData)
        .then(res => {
          if (res.data) {
            toast("Đã gửi thông tin thành công, Trường sẽ liên hệ với bạn sau", {
              type: "success",
              autoClose: 3000,
            });
            setFormData({
              fullname: "",
              phone: "",
              content: "",
            })
            setIsSending(false);
          }
        })
        .catch(err => {
          console.log(err)
          toast("Có lỗi xảy ra, vui lòng thử lại sau", {
            type: "error",
            autoClose: 2000,
          });
        })
    }
  };

  const validateFormData = (formData) => {
    const { fullname, phone, content } = formData;

    var errors = 0;

    // Validate fullname
    if (!fullname) {
      errors++;
      toast("Họ tên không được để trống", {
        type: "error",
        autoClose: 3000,
      });
    } else if (fullname.length < 2 || fullname.length > 50) {
      errors++;
      toast("Họ tên phải từ 2 đến 50 ký tự", {
        type: "error",
        autoClose: 3000,
      });
    }

    // Validate phone
    const phoneRegex = /^[0-9]{10,15}$/;
    if (!phone) {
      errors++;
      toast("Số điện thoại là bắt buộc", {
        type: "error",
        autoClose: 3000,
      });
    } else if (!phoneRegex.test(phone)) {
      errors++;
      toast("Số điện thoại không hợp lệ", {
        type: "error",
        autoClose: 3000,
      });
    }

    return errors;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
  };

  return (
    <div
      className="form-index position-relative"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="wrap-content mt-5">
        {slug != "/" &&
          < Breadcrumb >
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`#`} className="breadcrumb-link-select">Liên hệ</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      <div className="form-contain">
        <div className="title-main">
          <p>Liên hệ với</p>
          <span>Mầm non an thơ</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
          <p className="slogan-from">
            Nhận tin tức, cập nhật mới nhất và nhiều tin tức khác mỗi tuần.
          </p>
        </div>
        <Form className=" form-newsletter" onSubmit={sendMail}>
          <div className="row">
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="text"
                    className="form-control text-sm"
                    id="fullname-newsletter"
                    name="fullname"
                    placeholder="Nhập họ tên"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                  <label htmlFor="fullname-newsletter">Nhập họ tên</label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="tel"
                    className="form-control text-sm"
                    id="phone-newsletter"
                    name="phone"
                    placeholder="Nhập số điện thoại"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <label htmlFor="phone-newsletter">Điện thoại</label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="text"
                    className="form-control text-sm"
                    id="content-newsletter"
                    name="content"
                    placeholder="Nhập Nội dung"
                    value={formData.content}
                    onChange={handleChange}
                  />
                  <label htmlFor="content-newsletter">Nội dung</label>
                </div>
              </div>
            </div>
          </div>
          <div className="newsletter-button d-flex justify-content-center">
            <input
              type="hidden"
              name="dataNewsletter[type]"
              value="dangkynhantin"
            />
            <input
              type="hidden"
              name="dataNewsletter[date_created]"
              value="1723536723"
            />
            <input
              type="reset"
              value="Nhập lại"
              className="spacing-letter"
              onClick={() =>
                setFormData({
                  fullname: "",
                  phone: "",
                  content: "",
                })
              }
            />
            <input
              type="submit"
              className="spacing-letter btn btn-sm  w-100"
              name="submit-newsletter"
              value="Đăng ký"
            />
            <input
              type="hidden"
              className="btn btn-sm btn-danger w-100"
              name="recaptcha_response_newsletter"
              id="recaptchaResponseNewsletter"
            />
          </div>
        </Form>
      </div>

      <div className="des-form--after">
        {/* <img
          alt="MẦM NON AN THƠ"
        /> */}
      </div>
    </div>
    </div >
  );
};

export default FormComponent;
