import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ImageTitle from "../assets/image/des_title_main.png";
import imageArrow from "../assets/image/arrows.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  const getPost = (slug) => {
    axios
      .get("/api/v1/posts/get-by-cate-slug/" + slug)
      .then((res) => {
        setPosts(res.data.posts)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  useEffect(() => {
    getPost('tin-tuc')
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    rows: 2,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 20,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
    ],
  };

  return (
    <div className="container-wrapper my-5">
      <div className="newsHot-index" data-aos="fade-up" data-aos-duration="1000">
        <div className="wrap-content">
          <div className="title-main">
            <span>tin tức & sự kiện</span>
            <i>
              <img src={ImageTitle} alt="Title" />
            </i>
          </div>
          <div className="newsHot-contain-blog">
            <Slider {...settings} className="slick-slider">
              {posts.map((item) => (
                <div key={item.id} className="news-col">
                  <div className="news-item" >
                    <div className="news-pic">
                      <Link
                        to={`/tin-tuc/${item.slug}`}
                        className="text-decoration-none scale-img"
                      >
                        <img
                          className="w-100 image-post"
                          onError={(e) =>
                            (e.target.src = `${process.env.REACT_APP_BACKEND_URL}${item.imgURL}`)
                          }
                          src={`${item.imagePath}`}
                          alt={item.title}
                        />
                      </Link>
                    </div>
                    <div className="news-info vw-100">
                      <h3>
                        <Link
                          to={`/tin-tuc/${item.slug}`}
                          className="text-split text-decoration-none"
                        >
                          {item.title}
                        </Link>
                      </h3>
                      <span className="text-split">{item.description}</span>
                      <div className="btn-xemthem xemthem-bg-static">
                        <Link
                          to={`/tin-tuc/${item.slug}`}
                          className="text-decoration-none spacing-letter"
                        >
                          Chi tiết
                          {/* <img src={imageArrow} alt="Arrow" /> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="btn-xemthem mb-3 mt-2">
              <Link to="/tin-tuc" className="text-decoration-none spacing-letter">
                Xem tất cả <img src={imageArrow} alt="Arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
