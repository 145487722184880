import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import RoleModal from "./RoleModal";

const RoleTable = () => {
  const [roles, setRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    axios
      .get("/api/v1/roles", {
        params: {
          page: 1,
          // limit: 5,
          keyword: ''
        }
      })
      .then((res) => {
        setRoles(res.data.roles || []);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa phân quyền này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/roles/${id}`)
          .then((res) => {
            toast("Xoá phân quyền thành công", {
              type: "success",
              autoClose: 1000,
            });
            getRoles()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getRoles();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm quyền
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Tên</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((navbar, index) => (
            <tr key={navbar.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{navbar.name}</span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(navbar.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(navbar.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal ? (
        <RoleModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default RoleTable;
