import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link
import Mmenu from "mmenu-js";
import "mmenu-js/dist/mmenu.css";
import Logo from "../assets/image/logo.png";
import axios from "axios";

const Menu = ({ isScrolled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navbars, setNavbars] = useState([]);
  const location = useLocation()
  const [activeLink, setActiveLink] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isShow, setIsShow] = useState(false);

  const showMenu = () => {
    console.log(">>>>");
    setIsShow(!isShow);
  };

  useEffect(() => {
    new Mmenu(document.querySelector("#my-menu"));
    setActiveLink(location.pathname)
    axios.get("/api/v1/categories", {
      params: {
        sort: 'ASC'
      }
    })
      .then(res => {
        setNavbars(res.data.categories)
      })
      .catch(err => console.log(err))
  }, [location]);

  return (
    <div className={`w-menu ${isMenuOpen ? "menu-open" : ""}`}>
      <div className={`menu menu-index ${isScrolled ? "scrolled" : ""}`}>
        <div className="wrap-content">
          <ul className="menu-main">
            <li>
              <Link className="logo-head" to="/" title="MẦM NON AN THƠ">
                <img
                  onError={(e) => (e.target.src = { Logo })}
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </li>
            {navbars.map((navbar) => (
              <li key={navbar.id}>
                <Link
                  // className="transition"
                  className={`transition ${activeLink === `/${navbar.slug}` ? 'active' : ''}`}
                  to={`/${navbar.slug}`}
                  title={navbar.name}
                  onClick={() => setActiveLink(`/${navbar.slug}`)}
                >
                  {navbar.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="menu-res">
        <div className="menu-bar-res d-flex align-items-center justify-content-between">
          <a
            id="hamburger"
            href="#my-menu"
            title="Menu"
            onClick={() => showMenu()}
          >
            <span></span>
          </a>

          <Link className="logo-res" to="/">
            <img src={Logo} alt="logo" title="logo" />
          </Link>
        </div>

        <nav id="my-menu" style={{ display: isShow ? "block" : "none" }}>
          <ul className="menu-main">
            <li>
              <Link className="logo-head" to="/" title="MẦM NON AN THƠ">
                <img
                  onError={(e) => (e.target.src = { Logo })}
                  src={Logo}
                  alt="Logo"
                />
              </Link>{" "}
              <Link
                className="d-flex justify-content-end"
                title="MẦM NON AN THƠ"
                onClick={() => console.log()}
              >
                X
              </Link>{" "}
            </li>
            {navbars.map((navbar) => (
              <li key={navbar.id}>
                <Link
                  className="transition"
                  to={`/${navbar.slug}`}
                  title={navbar.name}
                >
                  {navbar.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
