import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      username: account,
      password: password,
    };

    axios
      .post("/api/v1/users/login", data)
      .then((res) => {
        if (res.data) {
          localStorage.setItem("token_antho", res.data.accessToken);
          localStorage.setItem("username", account);
          toast("Đăng nhập thành công", {
            type: "success",
            autoClose: 2000,
          });
          navigate("/admin");
        } else {
          toast(res.data.message.userName || "Đăng nhập lỗi", {
            type: "error",
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
        <Row className="w-100">
          <Col xs={12} md={6} lg={4} className="mx-auto">
            <Card className="shadow-lg border-0 rounded-lg">
              <Card.Body className="p-5">
                <h2 className="text-center mb-4">Đăng nhập</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Tài khoản</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nhập tài khoản"
                      value={account}
                      onChange={(e) => setAccount(e.target.value)}
                      required
                      className="rounded-pill"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className="mt-3">
                    <Form.Label>Mật khẩu</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Nhập mật khẩu"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="rounded-pill"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100 rounded-pill"
                  >
                    Đăng nhập
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <a href="#forgot-password" className="text-muted">
                    Quên mật khẩu?
                  </a>
                </div>
                <div className="text-center mt-3">
                  <a href="#register" className="text-primary">
                    Tạo tài khoản mới
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
