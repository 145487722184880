import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const SolutionAddModal = ({ id, addId, handleCloseModal, showModal }) => {
  const [isSendForm, setIsSendForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [products, setProducts] = useState([])
  const [formData, setFormData] = useState({
    name: "",
    productId: addId,
  });

  useEffect(() => {
    if (id) {
      axios.get("/api/v1/product-infos/" + id)
        .then(res => {
          setFormData({
            ...res.data
          });
        })
        .catch((error) => {
          toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
            type: "error",
            autoClose: 1000,
          });
        });
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      console.log(formData);

      axios.post("/api/v1/product-infos", formData)
        .then(res => {
          setIsSendForm(false);
          toast("Thêm chi tiết chương trình thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      axios.put("/api/v1/product-infos/" + id, formData)
        .then(res => {
          setIsSendForm(false);
          toast("Cập nhật chi tiết chương trình thành công", {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        })
        .catch(err => {
          console.log(err);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        })
    }
  };

  const validateForm = async (data) => {
    const errors = {};

    // Kiểm tra tên
    if (!data.name || data.name.trim() === "") {
      errors.name = "Tên không được để trống.";
    }
    return errors;
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>{id ? 'Chỉnh sửa' : 'Thêm mới'} chi tiết chương trình</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Floating className="mb-3">
            <Form.Control type="text" value={formData.name}
              onChange={(e) => {
                setErrors({ ...errors, name: "" })
                setFormData({ ...formData, name: e.target.value })
              }}
              isInvalid={!!errors.name} />
            <Form.Label>Tên<i className="text-danger">*</i></Form.Label>
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Floating>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          form="add-solution-hook-form"
          variant="primary"
          disabled={isSendForm}
          onClick={id ? handleUpdate : handleSubmit}
        >
          Lưu
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionAddModal;
