import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ListGroup,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./dashboard.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import PostTable from "./Post/Post.js";
import UserTable from "./User/User.js";
import CategoryTable from "./Category/Category.js";
import NavigationTable from "./Navbar/Navbar.js";
import ImageTable from "./Image/Image.js";
import CustomerTable from "./Customer/Customer.js";
import ProductTable from "./Product/Product.js";
import ProductInfoTable from "./ProductInfo/ProductInfo.js";
import RoleTable from "./Role/Role.js";
import PlaceTable from "./Place/Place.js";
import axios from "axios";
import { jwtDecode } from "jwt-decode"

const Dashboard = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 767);
  const [title, setTitle] = useState("")
  const [isManageUser, setIsManageUser] = useState(false)
  const [isManageNavigation, setIsManageNavigation] = useState(false)
  const [isManageCategory, setIsManageCategory] = useState(false)
  const [isManagePost, setIsManagePost] = useState(false)
  const [isManageImage, setIsManageImage] = useState(false)
  const [isManageCustomer, setIsManageCustomer] = useState(false)
  const [isManageProduct, setIsManageProduct] = useState(false)
  const [isManageProductInfo, setIsManageProductInfo] = useState(false)
  const [isManageRole, setIsManageRole] = useState(false)
  const [isManageplace, setIsManagePlace] = useState(false)
  const [name, setName] = useState(false)

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    document.title = "Trang quản lý";
    const token = localStorage.getItem("token_antho")
    if (token) {
      const isTime = checkTokenExpiration(token)
      if (isTime) {
        getUsers()
      } else {
        navigate("/login")
      }
    }
  }, []);

  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded.exp < currentTime) {
        toast("Hết thời gian làm việc, vui lòng đăng nhập lại", {
          type: "error",
          autoClose: 2000,
        });
        localStorage.clear();
        return false;
      }
      return true;
    } catch (error) {
      console.error("Token không hợp lệ hoặc bị lỗi:", error);
      return false;
    }
  };

  const getUsers = () => {
    const username = localStorage.getItem("username")
    axios.get("/api/v1/users/user-name/" + username)
      .then(res => {
        setName(res.data.name)
      })
      .catch(err => {
        console.log(err);
        toast("Có lỗi xảy ra", {
          type: "error",
          autoClose: 2000,
        });
      })
  }

  const Logout = () => {
    swal({
      title: `Bạn muốn đăng xuất`,
      icon: "warning",
      buttons: ["Đóng", "Đăng xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        navigate("/login");
        toast("Đăng xuất thành công", {
          type: "success",
          autoClose: 2000,
        });
        localStorage.clear();
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsManageUser(false);
    setIsManageNavigation(false);
    setIsManageCategory(false);
    setIsManagePost(false);
    setIsManageImage(false);
    setIsManageCustomer(false);
    setIsManageProduct(false);
    setIsManageProductInfo(false);
    setIsManageRole(false);
    setIsManagePlace(false);

    if (location.pathname.startsWith("/manage-users")) {
      setTitle("Quản lý người dùng")
      setIsManageUser(true)
    }
    if (location.pathname.startsWith("/manage-navigations")) {
      setTitle("Quản lý điều hướng")
      setIsManageNavigation(true)
    }
    if (location.pathname.startsWith("/manage-categories")) {
      setTitle("Quản lý danh mục")
      setIsManageCategory(true)
    }
    if (location.pathname.startsWith("/manage-posts")) {
      setTitle("Quản lý bài viết")
      setIsManagePost(true)
    }
    if (location.pathname.startsWith("/manage-images")) {
      setTitle("Quản lý hình ảnh")
      setIsManageImage(true)
    }
    if (location.pathname.startsWith("/manage-customers")) {
      setTitle("Quản lý người liên hệ")
      setIsManageCustomer(true)
    }
    if (location.pathname.startsWith("/manage-roles")) {
      setTitle("Quản lý phân quyền")
      setIsManageRole(true)
    }
    if (location.pathname.startsWith("/manage-places")) {
      setTitle("Quản lý địa chỉ")
      setIsManagePlace(true)
    }
    if (location.pathname.startsWith("/manage-products")) {
      if (id) {
        setTitle("Quản lý chi tiết chương trình học")
        setIsManageProductInfo(true)
      } else {
        setTitle("Quản lý chương trình học")
        setIsManageProduct(true)
      }
    }
  }, [navigate, id])

  const renderTable = () => {
    if (isManageUser) {
      return <UserTable />;
    }
    else if (isManageNavigation) {
      return <NavigationTable />;
    }
    else if (isManageCategory) {
      return <CategoryTable />;
    }
    else if (isManagePost) {
      return <PostTable />;
    }
    else if (isManageImage) {
      return <ImageTable />;
    }
    else if (isManageCustomer) {
      return <CustomerTable />;
    }
    else if (isManageProduct) {
      return <ProductTable />;
    }
    else if (isManageProductInfo) {
      return <ProductInfoTable />
    }
    else if (isManageRole) {
      return <RoleTable />
    }
    else if (isManageplace) {
      return <PlaceTable />
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <Col
            md={isCollapsed ? 1 : 3}
            lg={isCollapsed ? 1 : 2}
            className={`bg-dark text-white min-vh-100 p-0 sidebar ${isCollapsed ? "collapsed" : ""
              }`}
          >
            <Navbar
              bg="dark"
              variant="dark"
              className="flex-column align-items-start p-3"
            >
              <Button variant="dark" onClick={toggleSidebar} className="mb-3">
                {isCollapsed ? "☰" : "✖"}
              </Button>
              <Navbar.Brand
                as={Link}
                to="/"
                className={`${isCollapsed ? "d-none" : ""}`}
              >
                QUẢN LÝ
              </Navbar.Brand>
              <Nav className="flex-column w-100">
                <ListGroup variant="flush">
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-users"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      🧑 Quản lý người dùng
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>🧑</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-customers"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      🙍 Quản lý người liên hệ
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>🙍</span>
                  </ListGroup.Item>
                  {/* <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-navigations"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      ➡️ Quản lý điều hướng
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>➡️</span>
                  </ListGroup.Item> */}
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-categories"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      📒 Quản lý danh mục
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>📒</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-products"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      📄 Quản lý chương trình học
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>📄</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-posts"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      ✍ Quản lý bài viết
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>✍</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-images"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      🖼️ Quản lý hình ảnh
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>🖼️</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-places"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      🗺️ Quản lý địa chỉ
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>🗺️</span>
                  </ListGroup.Item>
                  {/* <ListGroup.Item
                    action
                    as={Link}
                    to="/manage-roles"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      ✍ Quản lý phân quyền
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>✍</span>
                  </ListGroup.Item> */}
                  <ListGroup.Item
                    action
                    onClick={() => Logout()}
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      🔙Đăng xuất
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>🔙</span>
                  </ListGroup.Item>
                </ListGroup>
              </Nav>
            </Navbar>
          </Col>

          {/* Content */}
          <Col
            md={isCollapsed ? 11 : 9}
            lg={isCollapsed ? 11 : 10}
            className={`p-4 content ${isCollapsed ? "collapsed" : ""}`}
            style={{ marginLeft: isCollapsed ? "100px" : "16%" }}
          >
            <h2>{title == "" ? `Xin chào, ${name}` : title}</h2>
            {renderTable()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
