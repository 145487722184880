import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import ImageModal from './ImageModal'
import { Pagination } from "react-bootstrap";

const ImageTable = () => {
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getImage();
  }, [currentPage]);

  const getImage = () => {
    axios
      .get("/api/v1/images", {
        params: {
          page: currentPage,
          limit: 5,
          keyword: ''
        }
      })
      .then((res) => {
        setImages(res.data.images || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeleteImage = (id) => {
    swal({
      title: `Bạn muốn xóa hình ảnh này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/images/${id}`)
          .then((res) => {
            toast("Xoá hình ảnh thành công", {
              type: "success",
              autoClose: 1000,
            });
            getImage()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getImage();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  function getImageType(type) {
    switch (type) {
      case 0:
        return "";
      case 1:
        return "Trang chủ";
      case 2:
        return "Thư viện ảnh";
      default:
        return "Không hợp lệ";
    }
  }

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm hình ảnh
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Hình ảnh</th>
            <th>Loại</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {images.map((image, index) => (
            <tr key={image.id}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={image.path}
                  alt="Image"
                  style={{
                    width: '150px',
                    height: '100px',
                    objectFit: 'cover'
                  }}
                />
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {getImageType(image.type)}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(image.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteImage(image.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      {showModal ? (
        <ImageModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default ImageTable;
