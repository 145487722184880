import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";
import PostModal from "./PostModal"
import { Pagination } from "react-bootstrap";

const PostTable = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [updateId, setUpdateId] = useState(null)
  const [keyword, setKeyword] = useState("")
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getPost();
    getCategory();
  }, [keyword, currentPage]);

  const getPost = () => {
    axios
      .get("/api/v1/posts", {
        params: {
          page: currentPage,
          limit: 5,
          keyword: keyword
        }
      })
      .then((res) => {
        setPosts(res.data.posts || []);
        setTotalPages(res.data.totalPages)
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const getCategory = () => {
    axios
      .get("/api/v1/categories", {
        params: {
          page: 1,
          keyword: ''
        }
      })
      .then((res) => {
        setCategories(res.data.categories || []);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa bài viết này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/api/v1/posts/${id}`)
          .then((res) => {
            toast("Xoá bài viết thành công", {
              type: "success",
              autoClose: 1000,
            });
            getPost()
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    setUpdateId(null)
    if (isReload) {
      getPost();
    }
  };

  const handleShowAddModal = (id) => {
    setShowModal(true);
    setUpdateId(id)
    setSelectedSolution();
  };

  const getNameById = (id, array) => {
    const item = array.find(obj => obj.id === id);
    return item ? item.name : null;
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end mt-3"
      >
        Thêm bài viết
      </Button>
      <input
        type="text"
        placeholder="Tìm kiếm bài viết"
        className="form-control mb-2 float-start mt-3"
        style={{ width: 350 }}
        onChange={(e) => {
          setCurrentPage(1)
          setKeyword(e.target.value)
        }}
      />
      <Table striped bordered hover >
        <thead>
          <tr className="text-center">
            <th style={{ width: '30px'}}>STT</th>
            <th style={{ width: '200px' }}>Tiêu đề</th>
            <th style={{ width: '150px' }}>Slug</th>
            <th style={{ width: '300px' }}>Mô tả</th>
            <th style={{ width: '150px' }}>Hình ảnh</th>
            <th style={{ width: '100px' }}>Danh mục</th>
            <th style={{ width: '100px' }}>#</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, index) => (
            <tr key={post.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines " style={{ width: '200px' }}>{post.title}</span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines " style={{ width: '150px' }}>
                  {post.slug}
                </span>
              </td>
              <td>
                <div className="text-ellipsis-2-lines" style={{ width: '300px' }}>
                  {post.description}
                </div>
              </td>
              <td>
                <img
                  src={post.imagePath}
                  alt="Image"
                  style={{ width: '200px', height: '100px', objectFit: 'cover' }}
                />
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {getNameById(post.categoryId, categories)}
                </span>
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => { handleShowAddModal(post.id) }}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(post.id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination */}
      <Pagination className="justify-content-center">
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {Array.from({ length: totalPages }, (_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      {showModal ? (
        <PostModal
          id={updateId}
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
    </>
  );
};

export default PostTable;
