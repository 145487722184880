import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../index.css"
import ImageTitle from "../assets/image/des_title_main.png";
import imageArrow from "../assets/image/arrows.png";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Study = () => {
  const [data, setData] = useState([])
  const location = useLocation();
  const slug = location.pathname;

  useEffect(() => {
    axios.get("/api/v1/products", { params: { sort: "ASC" } })
      .then(async (res) => {
        const products = res.data.products;
        const formattedData = await Promise.all(
          products.map(async (product) => {
            const productInfo = await axios
              .get(`/api/v1/product-infos/product-id/${product.id}`)
              .then((res) => res.data.productInfos)
              .catch((err) => {
                console.error(`Error fetching product info for ID ${product.id}`, err);
                return [];
              });
            return {
              id: product.id,
              title: product.title || "",
              image: product.image || "",
              link: `/chuong-trinh-hoc/${product.link}`,
              items: productInfo,
            };
          })
        );
        setData(formattedData);
      })
      .catch((err) => console.error("Error fetching products", err));
  }, []);

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   arrows: false,
  // };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div
      className=" chuongtrinhhoc-index padding-main"
    // data-aos="fade-up"
    // data-aos-duration="1000"
    >
      <div className="wrap-content">
        {
          slug != "/" && <Breadcrumb>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`/`} className="breadcrumb-link">Trang chủ</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumb-item-custom">
              <Link to={`#`} className="breadcrumb-link-select">Chương trình học</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        <div className="title-main">
          <span>Chương trình học</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="chuongtrinhhoc-contain">
          <Slider {...settings} className="testClass">
            {data.map((item, idx) => (
              <div key={idx} className="chuongtrinhhoc">
                <div className="">
                  <div className="chuongtrinhhoc-pic">
                    <p className="mb-0">
                      <img src={item.image} alt={item.image} />
                    </p>
                  </div>
                  <div className="chuongtrinhhoc-info">
                    <h3>
                      <Link
                        to={item.link}
                        className="text-split spacing-letter">
                        {item.title}
                      </Link>
                    </h3>
                    <div>
                      <ul>
                        {item.items.map((i, index) => (
                          <li key={index}>
                            <span style={{ fontSize: "14px" }}>{i.name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="btn-xemthem-cth">
                    {slug == "/" &&
                      < Link
                        to={item.link}
                        className="text-decoration-none spacing-letter"
                      >
                        <i>
                          <img src={imageArrow} alt="View More" />
                        </i>
                      </Link>
                    }
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div >
    </div >
  );
};

export default Study;
