import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import ClientPage from "./pages/ClientPage";
import MainPage from "./pages/MainMenu";
import IntroPage from "./pages/IntroPage";
import BlogDetail from "./pages/BlogDetail";
import "mmenu-js/dist/mmenu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/404";
import ContactPage from "./pages/ContactPage";
import TinTuc from "./pages/TinTuc";
import DetailPage from "./pages/PostDetail";
import StudyDetail from "./pages/StudyDetail";
import ImageLibraryPage from "./pages/ImageLibrary";
import StudyNew from "./components/StudyNew";
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}`;

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] =
  process.env.REACT_APP_CLIENT_URL || "*";
// axios.defaults.headers["Authorization"] = localStorage.getItem("token_antho")
//   ? "Bearer " + localStorage.getItem("token_antho")
//   : "";
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token_antho");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.defaults.headers["Shop-Domain"] = process.env.REACT_APP_SHOP_DOMAIN_URL;
function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<ClientPage />}>
          <Route index element={<MainPage />} />
          <Route path="chuong-trinh-hoc" element={<StudyNew />} />
          <Route path="chuong-trinh-hoc/:slug" element={<StudyDetail />} />
          <Route path="lien-he" element={<ContactPage />} />

          {/* <Route path="gioi-thieu" element={<PostDetail />} />
          <Route path="tuyen-sinh" element={<TuyenSinh />} />
          <Route path="moi-truong-hoc" element={<MoiTruong />} /> */}
          <Route path="thu-vien-anh" element={<ImageLibraryPage />} />
          <Route path=":id" element={<DetailPage />} />

          <Route path="tin-tuc" element={<TinTuc />} />
          <Route path="tin-tuc/:slug" element={<BlogDetail />} />
        </Route>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/manage-posts" element={<AdminPage />} />
        <Route path="/manage-categories" element={<AdminPage />} />
        <Route path="/manage-navigations" element={<AdminPage />} />
        <Route path="/manage-users" element={<AdminPage />} />
        <Route path="/manage-images" element={<AdminPage />} />
        <Route path="/manage-customers" element={<AdminPage />} />
        <Route path="/manage-products" element={<AdminPage />} />
        <Route path="/manage-roles" element={<AdminPage />} />
        <Route path="/manage-places" element={<AdminPage />} />
        <Route path="/manage-products/:id" element={<AdminPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
